<template>
  <div class="help-detail">
    <helloWorld />
    <div class="helpTop">
      
      <h3> <span></span>平台服务协议</h3>
    </div>
    <div class="helpMain">
      <div class="content">
        感谢您使用我们的服务。融汇科技的提供的该平台服务由亚联融汇（北京）科技有限公司提供，服务运营权属于亚联融汇（北京）科技有限公司。 
        <br />
        各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。 
        <br />
        <span style="font-weight:bold">一、协议签约</span>
        <br />
        1.1您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向融汇科技咨询。 
        <br />
        1.2当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与融汇科技平台达成一致，成为该平台“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
        <br />
        <span style="font-weight:bold">二、 协议范围 </span>
        <br />
        2.1签约主体 
        <br />
        本协议由您与融汇科技平台经营者共同缔结，本协议对您与融汇科技平台经营者均具有合同效力。
        <br />
        2.2 补充协议 
        <br />
        由于互联网高速发展，您与融汇科技签署的本协议列明的条款并不能完整罗列并覆盖您与融汇科技平台的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，融汇科技平台法律声明及隐私政策、融汇科技平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用融汇科技平台服务，视为您同意上述补充协议。 
        <br />
        <span style="font-weight:bold">三、 账户注册与使用 </span>
        <br />
        3.1用户资格 
        <br />
        在您开始注册程序使用融汇科技平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。 此外，您还需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则您应当停止使用融汇科技平台服务，同时您理解违反前述要求可能会造成您无法正常注册及使用融汇科技平台服务。 
        <br />
        3.2账户说明 
        <br />
        3.2.1账户注册
        <br />
        当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得融汇科技平台账户并成为融汇科技平台用户。融汇科技平台只允许每位用户使用一个融汇科技平台账户。如有证据证明或融汇科技根据融汇科技平台规则判断您存在不当注册或不当使用多个融汇科技平台账户的情形，融汇科技平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给融汇科技平台及相关方造成损失的，您还应承担赔偿责任。 
        <br />
        3.2.2账户使用
        <br />
        您有权使用您设置或确认的方式（以下简称“账户名称”）登录融汇科技平台。 由于您的融汇科技平台账户关联您的个人信息及融汇科技平台商业信息，您的融汇科技平台账户仅限您本人使用。未经融汇科技平台同意，您直接或间接授权第三方使用您融汇科技平台账户或获取您账户项下信息的行为无效。如融汇科技根据融汇科技平台规则中约定的违约认定程序及标准判断您融汇科技平台账户的使用可能危及您的账户安全及/或融汇科技平台信息安全的，融汇科技平台可拒绝提供相应服务或终止本协议。 
        <br />
        3.2.3实名认证
        <br />
        作为融汇科技平台经营者，为使您更好地使用融汇科技平台的各项服务，保障您的账户安全，融汇科技可要求您按相关法律法规规定完成实名认证。 
        <br />
        3.3注册信息管理 
        <br />
        3.3.1注册信息真实合法
        <br />
        在使用融汇科技平台服务时，您应当按融汇科技平台页面的提示准确完整地提供您的信息（包括您的姓名、身份证号码及电子邮件地址、联系电话、联系地址等），以便融汇科技或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。 
        <br />
        3.3.2更新维护
        <br />
        您应当及时更新您提供的信息，在法律有明确规定要求融汇科技作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，融汇科技将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。 如融汇科技按您最后一次提供的信息与您联系未果、您未按融汇科技的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及融汇科技造成的全部损失与不利后果。融汇科技可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部融汇科技平台服务，融汇科技对此不承担责任。 
        <br />
        3.4账户安全规范
        <br />
        3.4.1账户安全保管义务
        <br />
        您的账户为您自行设置并由您保管，融汇科技任何时候均不会主动要求您提供您的账户信息。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开融汇科技平台。 您的融汇科技账号必须使用密码和私钥保护。如果私钥遗失，您将无法要求找回加密资产。融汇科技不负责为用户存储或备份私钥信息。因此，融汇科技不对您私钥遗失造成的损失承担任何责任。用户须采取必要的手段来保障密码和私钥不被他人获取。如果有迹象表明账号可能被他人盗用，用户须立即通过客服邮箱（mirochain@163.com）联系融汇科技。 账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，融汇科技并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。
        <br />
        3.4.2账户行为责任自负
        <br />
        除融汇科技存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。
        <br />
        3.4.3日常维护须知
        <br />
        如发现任何未经授权使用您账户登录融汇科技平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知融汇科技。您理解融汇科技对您的任何请求采取行动均需要合理时间，且融汇科技应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除融汇科技存在法定过错外，融汇科技不承担责任。
        <br />
        <span style="font-weight:bold">四、 融汇科技平台服务及规范</span>
        <br />
        4.1您有权在融汇科技平台上享受商品及/或服务的浏览、收藏、购买、交易争议处理、信息交流如问答等服务。融汇科技提供的服务内容众多，具体您可登录融汇科技平台浏览。 
        <br />
        4.2在您浏览我们网站或客户端的过程中，融汇科技为您提供了信息分类、关键词检索、筛选、收藏及关注等功能，以更好地匹配您的需求。 
        <br />
        4.3商品及/或服务的购买
        <br />
        当您在融汇科技平台购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址（如需）、电话、收货人（如需）等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。
        您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱融汇科技平台正常交易秩序的行为。基于维护融汇科技平台交易秩序及交易安全的需要，融汇科技发现上述情形时可主动执行关闭相关交易订单等操作。
        <br />
        4.4交易争议处理
        <br />
        您在融汇科技平台交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
        <br />
        （一）与争议相对方自主协商；
        <br />
        （二）使用融汇科技平台提供的争议调处服务；
        <br />
        （三）请求消费者协会或者其他依法成立的调解组织调解；
        <br />
        （四）向有关行政部门投诉；
        <br />
        （五）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；
        <br />
        （六）向人民法院提起诉讼。
        <br /> 
        如您依据融汇科技平台规则使用融汇科技平台的争议调处服务，则表示您认可并愿意履行融汇科技平台的客服作为独立的第三方根据其所了解到的争议事实并依据融汇科技平台规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在融汇科技平台调处决定作出前，您可选择上述（三）、（四）、（五）、（六）途径（下称“其他争议处理途径”）解决争议以中止融汇科技平台的争议调处服务。如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。 
        <br />
        4.5 费用
        <br />
        融汇科技为融汇科技平台向您提供的服务付出了大量的成本，除融汇科技平台明示的收费业务外，融汇科技向您提供的服务目前是免费的。如未来融汇科技向您收取合理费用，融汇科技会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。 
        <br />
        4.6 责任限制 
        <br />
        4.6.1不可抗力及第三方原因
        <br />
        融汇科技依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，融汇科技并不承担相应的违约责任：
        <br />
        （一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
        <br />
        （二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；
        <br />
        （三）在融汇科技已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
        <br />
        4.6.2融汇科技仅向您提供融汇科技平台服务，您了解融汇科技平台上的信息系用户自行发布，且可能存在风险和瑕疵。融汇科技将通过依法建立相关检查监控制度尽可能保障您在融汇科技平台上的合法权益及良好体验。同时，鉴于融汇科技平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，融汇科技无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。
        <br />
        4.6.3您理解并同意，在争议调处服务中，融汇科技平台的客服并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意或重大过失外，调处方对争议调处决定免责。 
        <br />
        五、用户信息的保护及授权 
        <br />
        5.1 个人信息的保护
        <br />
        融汇科技非常重视用户个人信息（即能够以电子或者其他方式记录的或已识别或者可识别的自然人有关的各种信息）的保护，在您使用融汇科技提供的服务时，您同意融汇科技按照在融汇科技平台上公布的隐私权政策处理您的个人信息。融汇科技希望通过隐私权政策向您清楚地介绍融汇科技对您个人信息的处理方式。
        <br />
        5.2非个人信息的保证与授权 
        <br />
        5.2.1您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，融汇科技可对您发布的信息依法或依本协议进行删除或屏蔽。
        <br />
        5.2.2您应当确保您所发布的信息不包含以下内容：
        <br />
        （一）违反国家法律法规禁止性规定的；
        <br />
        （二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
        <br />
        （三）欺诈、虚假、不准确或存在误导性的；
        <br />
        （四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
        <br />
        （五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
        <br />
        （六）存在可能破坏、篡改、删除、影响融汇科技平台任何系统正常运行或未经授权秘密获取融汇科技平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
        <br />
        （七）其他违背社会公共利益或公共道德或依据相关融汇科技平台协议、规则的规定不适合在融汇科技平台上发布的。
        <br />
        5.2.3对于您提供、发布及在使用融汇科技平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，均不会因上传、发布等行为发生知识产权、肖像权等权利的转移。除非我们另行说明，在法律允许的范围内，您免费授予融汇科技平台非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。 
        <br />
        六、 用户的违约及处理 
        <br />
        6.1发生如下情形之一的，视为您违约：
        <br />
        （一）使用融汇科技平台服务时违反有关法律法规规定的；
        <br />
        （二）违反本协议或本协议补充协议（即本协议第2.2条）约定的。 为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，融汇科技可在融汇科技平台规则中约定违约认定的程序和标准。如：融汇科技可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。 
        <br />
        6.2违约处理措施
        <br />
        6.2.1您在融汇科技平台上发布的信息构成违约的，融汇科技可根据相应规则立即对相应信息进行删除、屏蔽处理。
        <br />
        6.2.2您在融汇科技平台上实施的行为，或虽未在融汇科技平台上实施但对融汇科技平台及其用户产生影响的行为构成违约的，融汇科技可依据相应规则对您执行账户扣分、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，融汇科技可查封您的账户，终止向您提供服务。
        <br />
        6.2.3当您违约的同时存在欺诈、售假、盗用他人账户等特定情形或您存在危及他人交易安全或账户安全风险时，融汇科技会依照您行为的风险程度对您的账户采取取消收款、资金止付等强制措施。
        <br />
        6.2.4融汇科技可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在融汇科技平台上予以公示。 
        <br />
        6.3 赔偿责任
        <br />
        如您的行为使融汇科技及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿融汇科技及/或其关联公司上述全部损失。 如您的行为使融汇科技及/或其关联公司遭受第三人主张权利，融汇科技及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。 
        <br /> 
        七、 协议的变更
        <br />
        融汇科技可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。
        如您不同意变更事项，您有权于变更事项确定的生效日前联系融汇科技反馈意见。如反馈意见得以采纳，融汇科技将酌情调整变更事项。
        如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用融汇科技平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用融汇科技平台服务，则视为您同意已生效的变更事项。 
        <br />
        八、 通知 
        <br />
        8.1有效联系方式
        <br />
        您在注册成为融汇科技平台用户，并接受融汇科技平台服务时，您应该向融汇科技提供真实有效的联系方式，对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
        您在注册融汇科技平台用户时生成的用于登陆融汇科技平台接收站内信、系统消息和即时信息的账号，也作为您的有效联系方式。
        融汇科技将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。 
        <br />
        8.2通知的送达
        <br />
        融汇科技通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在融汇科技平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送信息、系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。 对于在融汇科技平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件或现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。 您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。 您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。 你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。 
        <br />
        九、 协议的终止 
        <br />
        9.1您有权通过以下任一方式终止本协议：
        <br />
        （一）在满足融汇科技平台公示的账户注销条件时您通过网站自助服务注销您的账户的；
        <br />
        （二）变更事项生效前您停止使用并明示不愿接受变更事项的；
        <br />
        （三）您明示不愿继续使用融汇科技平台服务，且符合融汇科技平台终止条件的。
        <br />
        9.2出现以下情况时，融汇科技可以本协议第八条的所列的方式通知您终止本协议：
        <br />
        （一）您违反本协议约定，融汇科技依据违约条款终止本协议的；
        <br />
        （二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，融汇科技依据融汇科技平台规则对您的账户予以查封的；
        <br />
        （三）除上述情形外，因您多次违反融汇科技平台规则相关规定且情节严重，融汇科技依据融汇科技平台规则对您的账户予以查封的；
        <br />
        （四）您的账户被融汇科技依据本协议回收的；
        <br />
        （五）其它应当终止服务的情况。 
        <br />
        9.3协议终止后的处理
        <br />
        9.3.1本协议终止后，除法律有明确规定外，融汇科技无义务向您或您指定的第三方披露您账户中的任何信息。
        <br />
        9.3.2本协议终止后，融汇科技仍享有下列权利：
        <br />
        （一）继续保存您留存于融汇科技平台的本协议第五条所列的各类信息；
        <br />
        （二）对于您过往的违约行为，融汇科技仍可依据本协议向您追究违约责任。
        <br />
        9.3.3本协议终止后，对于您在本协议存续期间产生的交易订单，融汇科技可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。 
        <br />
        十、法律适用、管辖与其他 
        <br />
        10.1协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
        <br />
        10.2您因使用融汇科技平台服务所产生及与融汇科技平台服务有关的争议，由融汇科技与您协商解决。协商不成时，任何一方均可向杭州米链科技有限公司所在地有管辖权的人民法院提起诉讼。
        <br />
        10.3本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.help-detail .helpTop h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: #1d202b;
    margin-bottom: 30px;
}
.help-detail .helpTop h3 span {
    width: 4px;
    height: 24px;
    background: #3d61ff;
    display: inline-block;
    margin-right: 10px;
}
.help-detail .helpTop {
    padding: 50px 0px 0;
    max-width: 840px;
    margin: 0 auto;
}
.help-detail .helpMain {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
}
.help-detail .helpMain  {
    font-size: 14px;
    font-weight: 400;
    color: #1d202b;
    line-height: 30px;
    min-height: 1150px;
}
</style>